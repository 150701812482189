(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;
  window.DESKTOP_WIDE_WIDTH = 1206;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');


  // Helpers
  // -------
  window.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i) !== null;
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  $WINDOW.on('resize', function() {
    window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
    window.WINDOW_HEIGHT = $WINDOW.height();
    window.HEADER_HEIGHT = $HEADER.height();
  });

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDE_WIDTH = function() {
    return WINDOW_WIDTH > DESKTOP_WIDE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH );
  };
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = function() {
    return WINDOW_WIDTH < PORTRAIT_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < SMALL_MOBILE_WIDTH;
  };


  // Variables
  // ---------
  var documentIsReady = false,
      glossaryTermHash = '',
      glossaryTerms = {},
      $goTopButton,
      $topInfoPanel,
      slickSettings = {
        autoplay: true,
        autoplaySpeed: 8000,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        pauseOnDotsHover: true,
        customPaging: function() { return $('<div />'); },
        draggable: true
      };


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '(\\+7|8) \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__',
      keepStatic: true
    }
  });

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');


  // Glossary term key hash check
  // ----------------------------
  var glossaryTermItemSelect = function() {
    glossaryTermHash = decodeURIComponent( location.hash.slice(1) );

    if( glossaryTermHash.length ) {
      var $termsContainer = $('.terms-block-container');

      if( $termsContainer.length ) {
        var $term = $termsContainer.children('[data-key="' + glossaryTermHash + '"]');

        $term.addClass('active').siblings().removeClass('active');
        $WINDOW.scrollTo($term, {duration: 150, margin: true, offset: -60});
      }
    }
  };


  $WINDOW
    // Window hashchange
    // -----------------
    .on('hashchange', function() {
      if( documentIsReady )
        glossaryTermItemSelect();
    })

    // Window scrolling
    // ----------------
    .on('scroll', function(e, visibleCssClass) {
      var scrollTop = $WINDOW.scrollTop();

      if ( !visibleCssClass )
        visibleCssClass = 'is-visible';

      if ( scrollTop >= HEADER_HEIGHT ) {
        if( $goTopButton )
          $goTopButton.addClass(visibleCssClass);
        if( $topInfoPanel )
          $topInfoPanel.addClass(visibleCssClass);
      } else {
        if( $goTopButton )
          $goTopButton.removeClass();
        if( $topInfoPanel )
          $topInfoPanel.removeClass();
      }
    })
    .trigger('resize');


  $BODY
    .on('yiiListViewUpdating', function(id) {
      $.mouseLoader(true);
    })
    .on('yiiListViewUpdated', function(id, data) {
      $.mouseLoader(false);
    });


  $DOCUMENT
    .ready(function() {
      documentIsReady = true;

      // Glossary term item select
      // -------------------------
      glossaryTermItemSelect();

      // Show topInfoPanel and goTopButton
      // ---------------------------------
      $WINDOW.trigger('scroll');


      // Main rotator
      $('.main-rotator-wrapper').slick($.extend({}, slickSettings, {
        centerMode: true,
        centerPadding: '0px',
        arrows: false,
        dots: true,
        appendDots: '.main-rotator-wrapper > .main-rotator-dots',
        slide: '.main-rotator-slide'
      }));

      // Portfolio clients rotator
      $('.portfolio-clients-rotator > .portfolio-clients-rotator-wrapper').slick($.extend({}, slickSettings, {
        centerMode: false,
        arrows: true,
        appendArrows: '.portfolio-clients-rotator > .portfolio-clients-rotator-arrows',
        dots: false,
        draggable: false,
        focusOnSelect: true,
        slide: '.portfolio-client-item',
        slidesToScroll: 1,
        slidesToShow: ($('.content-wrapper.with-left').length ? 5 : 6),
        swipe: false,
        responsive: [
          {
            breakpoint: LANDSCAPE_TABLET_WIDTH,
            settings: {slidesToShow: 4}
          },
          {
            breakpoint: PORTRAIT_TABLET_WIDTH,
            settings: {slidesToShow: 3}
          },
          {
            breakpoint: SMALL_MOBILE_WIDTH,
            settings: {slidesToShow: 2}
          }
        ]
      }));

      // Product gallery
      // ---------------
      $('.product-card .product-gallery').slick($.extend({}, slickSettings, {
        fade: true,
        arrows: false,
        dots: true,
        appendDots: '.product-gallery-container > .product-gallery-pager',
        customPaging: function(slick, index) {
          return $('<div />', {
            style: 'background-image:url("' + slick.$slides.eq(index).children().data('imagePre') + '")'
          });
        },
        draggable: false
      }));
    })

    // Scroll to
    // ---------
    .on('click.jsScrollTo', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = $lnk.data('scrollOffset') || 0;

      $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
    })

    // Tabs
    // ----
    .on('click.jsTabs', '.js-tabs a', function(e) {
      e.preventDefault();

      var $lnk = $(this).parent();

      if( !$lnk.hasClass('active') ) {
        var $parent = $lnk.parents('.js-tabs'),
            $destination = !!$parent.data('tabsDest') ? $($parent.data('tabsDest')) : $parent.next();

        $lnk.addClass('active').siblings().removeClass('active');
        $destination.children().addClass('dn').eq($lnk.index()).removeClass('dn');
      }
    })

    // Product tabs
    // ------------
    .on('click.productTabs', '.product-content-tabs a', function(e) {
      e.preventDefault();

      var $lnk = $(this);

      if( !$lnk.hasClass('active') ) {
        var tabId = $lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'));

        $lnk.addClass('active').siblings().removeClass('active');
        $('.product-content-wrapper').children(tabId).removeClass('hidden').siblings().addClass('hidden');
      }
    })

    // Glossary term notice open
    // -------------------------
    .on('click.glossaryTermLink', '.glossary-term', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          keyIndex = $lnk.attr('href').lastIndexOf('#');

      if( keyIndex !== -1 ) {
        var termKey = $lnk.attr('href').substr(keyIndex + 1);

        if( !glossaryTerms[termKey] ) {
          $.ajax({
            type: 'POST',
            url: 'glossary/' + termKey,
            beforeSend: function() { $.mouseLoader(true); },
            dataType: 'html'
          })
            .done(function(response) {
              glossaryTerms[termKey] = response;
              $.mouseLoader(false);
              $.fancybox.open(glossaryTerms[termKey]);
            })
            .fail(function() {
              $.mouseLoader(false);
              alert('Во время загрузки данных возникла ошибка, попробуйте позже.');
            });
        }
        else
          $.fancybox.open(glossaryTerms[termKey]);
      }
    })

    // Glossary term key hash set
    // --------------------------
    .on('click.glossaryTermItemName', '.terms-block-container .term-name a', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          keyIndex = $lnk.attr('href').lastIndexOf('#');

      if( keyIndex !== -1 )
        location.hash = encodeURIComponent( $lnk.attr('href').substr(keyIndex + 1) );
    })

    // Filter element dropdown show/hide
    // ---------------------------------
    .on('click.filterElementCaption', '.filter-elements-container .filter-dropdown-button', function(e) {
      e.preventDefault();

      $(this).parent().toggleClass('is-opened');
    });


  if( IS_DESKTOP_WIDTH() ) {
    $DOCUMENT
      .ready(function() {
        $goTopButton = $('#go-top-button');
        $topInfoPanel = $('#top-info-panel');

        // Content block move
        // ------------------
        //$('.left-column-block').prependTo('.left-column');

        // Load topInfoPanel content
        // -------------------------
        $.ajax({
          type: 'POST',
          url: '/ajax/render_content/',
          data: {
            ajaxRender: 'topInfoPanel'
          },
          dataType: 'html'
        })
          .done(function(response) {
            $topInfoPanel.html(response).parent().addClass('initialized');
          });
      })

      // Scroll to top
      // -------------
      .on('click.goTopButton', '#go-top-button', function(e) {
        e.preventDefault();

        $WINDOW.scrollTo(0, 200);
      });

    $WINDOW
      .on('load', function() {
        // Fix breadcrumbs items submenu popup position
        // --------------------------------------------
        var $breadcrumbs = $('.content .breadcrumbs'),
            breadcrumbsWidth = $breadcrumbs.outerWidth();

        $breadcrumbs.children().each(function() {
          var $item = $(this),
              $submenu = $item.find('ul:first');

          if( $submenu.length ) {
            $submenu.css('max-width', breadcrumbsWidth);

            var position = breadcrumbsWidth - $submenu.outerWidth() - $item.position().left;

            if( position < 0 )
              $submenu.css('left', position);
          }
        });
      });
  }


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      autoFocus: false,
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: function(widget, current) {
      switch(current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    }
  });

})();